import { EnvironmentProviders, makeEnvironmentProviders } from '@angular/core';

import { CLARITY_INITIALIZER_PROVIDER } from './initializers/clarity.initializer';
import { ClaritySettings } from './interfaces/clarity-settings.interface';
import { CLARITY_SETTINGS_TOKEN } from './tokens/clarity-settings.token';

export function provideEnvironmentClarity(configValue?: ClaritySettings): EnvironmentProviders {
  return makeEnvironmentProviders([
    {
      provide: CLARITY_SETTINGS_TOKEN,
      useValue: configValue,
    },
    CLARITY_INITIALIZER_PROVIDER,
  ]);
}
