import { EnvironmentProviders, makeEnvironmentProviders } from '@angular/core';
import { MIXPANEL_INITIALIZER_PROVIDER } from './initializers/mixpanel.initializer';
import { MixpanelSettings } from './interfaces/mixpanel-settings.interface';
import { MIXPANEL_SETTINGS_TOKEN } from './tokens/mixpanel-settings.token';

export function provideEnvironmentMixpanel(configValue?: MixpanelSettings): EnvironmentProviders {
  return makeEnvironmentProviders([
    {
      provide: MIXPANEL_SETTINGS_TOKEN,
      useValue: configValue,
    },
    MIXPANEL_INITIALIZER_PROVIDER,
  ]);
}
