import { EnvironmentProviders, makeEnvironmentProviders } from '@angular/core';
import { ConfigHubspot } from '@dougs/core/config';
import { HUBSPOT_INITIALIZER_PROVIDER } from './initializers/hubspot.initializer';
import { HUBSPOT_SETTINGS_TOKEN } from './tokens/hubspot-settings.token';

export function provideEnvironmentHubspot(configValue?: ConfigHubspot): EnvironmentProviders {
  return makeEnvironmentProviders([
    {
      provide: HUBSPOT_SETTINGS_TOKEN,
      useValue: configValue,
    },
    HUBSPOT_INITIALIZER_PROVIDER,
  ]);
}
