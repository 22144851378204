import { HttpClient, HttpErrorResponse } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { catchError, Observable, of } from 'rxjs';
import { AccountingOnboarding, AccountingOnboardingChecklistActionDto } from '@dougs/accounting/onboarding/dto';
import { Company } from '@dougs/company/dto';
import { AppConfig } from '@dougs/core/config';
import { SERVICE_STATE } from '@dougs/revenue/services/dto';

@Injectable({
  providedIn: 'root',
})
export class OnboardingHttpService {
  private readonly baseUrl = '/accounting-subscription-onboarding-api';

  constructor(private readonly http: HttpClient) {}

  getAccountingOnboarding(companyId: number): Observable<AccountingOnboarding | null> {
    return this.http
      .get<AccountingOnboarding | null>(
        `${AppConfig.settings.legacyApiServerUrl}${this.baseUrl}/onboarding/${companyId}`,
        {
          headers: { ['X-Silent-User-Message']: 'true' },
        },
      )
      .pipe(
        catchError((e: HttpErrorResponse) => {
          if (`${e.status}`.startsWith('4')) {
            // silent 4xx (client-sided) errors
            return of(null);
          }
          throw e;
        }),
      );
  }

  createAccountingOnboarding(companyId: number): Observable<AccountingOnboarding> {
    return this.http.post<AccountingOnboarding>(
      `${AppConfig.settings.legacyApiServerUrl}${this.baseUrl}/onboarding/${companyId}`,
      {},
    );
  }

  completeAccountingOnboarding(
    companyId: number,
    activationCompletedAt: Date | null,
  ): Observable<AccountingOnboarding> {
    return this.http.put<AccountingOnboarding>(
      `${AppConfig.settings.legacyApiServerUrl}${this.baseUrl}/onboarding/${companyId}/actions/complete-onboarding`,
      { activationCompletedAt },
    );
  }

  updateEligibilityParametersAccountingOnboarding(
    companyId: number,
    accountingOnboarding: AccountingOnboarding,
  ): Observable<AccountingOnboarding> {
    return this.http.put<AccountingOnboarding>(
      `${AppConfig.settings.legacyApiServerUrl}${this.baseUrl}/onboarding/${companyId}/actions/update-eligibility-parameters`,
      { data: accountingOnboarding.data },
    );
  }

  updatePackOptionsConfiguration(
    companyId: number,
    packOptionsConfiguration: AccountingOnboarding['data']['packOptionsConfiguration'],
  ): Observable<AccountingOnboarding> {
    return this.http.put<AccountingOnboarding>(
      `${AppConfig.settings.legacyApiServerUrl}${this.baseUrl}/onboarding/${companyId}/actions/update-pack-options-configuration`,
      packOptionsConfiguration,
    );
  }

  updateDesiredPlanParameters(
    companyId: number,
    desiredPlan: AccountingOnboarding['data']['desiredPlan'] | null,
  ): Observable<AccountingOnboarding> {
    return this.http.put<AccountingOnboarding>(
      `${AppConfig.settings.legacyApiServerUrl}${this.baseUrl}/onboarding/${companyId}/actions/update-desired-plan-parameters`,
      { data: { desiredPlan } },
    );
  }

  enableChecklist(company: Company, serviceState: SERVICE_STATE): Observable<AccountingOnboarding> {
    return this.http.put<AccountingOnboarding>(
      `${AppConfig.settings.legacyApiServerUrl}${this.baseUrl}/onboarding/${company.id}/checklist/enable`,
      {
        flags: company.flags,
        activityStartOrRegistrationDate: company.registrationDate,
        legalForm: company.legalForm,
        accountingServiceState: serviceState,
      } as AccountingOnboardingChecklistActionDto,
    );
  }

  disableChecklist(company: Company): Observable<AccountingOnboarding> {
    return this.http.put<AccountingOnboarding>(
      `${AppConfig.settings.legacyApiServerUrl}${this.baseUrl}/onboarding/${company.id}/checklist/disable`,
      {},
    );
  }

  archiveChecklistTip(companyId: number, tipId: string): Observable<AccountingOnboarding> {
    return this.http.put<AccountingOnboarding>(
      `${AppConfig.settings.legacyApiServerUrl}${this.baseUrl}/onboarding/${companyId}/checklist/tips/${tipId}/archive`,
      {},
    );
  }

  unarchiveChecklistTip(companyId: number, tipId: string): Observable<AccountingOnboarding> {
    return this.http.put<AccountingOnboarding>(
      `${AppConfig.settings.legacyApiServerUrl}${this.baseUrl}/onboarding/${companyId}/checklist/tips/${tipId}/unarchive`,
      {},
    );
  }
}
