export enum AccountingOnboardingState {
  UNKNOWN = 'unknown',
  PENDING = 'pending',
  COMPLETED = 'completed',
}

export interface PendingAppointmentData {
  scheduledAt?: Date;
  accountantName?: string;
  accountantAvatarUrl?: string;
}

export interface AppointmentData extends PendingAppointmentData {
  completedAt?: Date;
  completionReasonCode?: string;
  completionReasonComment?: string;
}

export interface AccountingOnboarding {
  companyId: number;
  activationCompletedAt?: Date;
  data: {
    accountingInitialAppointment: AppointmentData;
    accountingWelcomeThirtyMinutesAppointment?: AppointmentData;
    accountingOnboarderAppointments?: {
      pendingAppointment?: PendingAppointmentData;
      completedAppointments?: AppointmentData[];
    };
    webinar: {
      registeredAt: Date;
      attendedAt: Date;
      attendanceRate: number;
    };
    isEligible?: boolean;
    interestedInAccounting?: boolean;
    desiredPlan?: string;
    workshops: AccountingOnboardingWorkshop[];
    shouldShowCompanyCreationService?: boolean;
    packOptionsConfiguration?: { ecommerce?: 'basic' | 'ossOrIoss' | 'none' };
    checklist?: AccountingOnboardingChecklist;
  };
}

export interface AccountingOnboardingWorkshop {
  registeredAt: Date;
  attendedAt: Date;
  attendanceRate: number;
}

export interface AccountingOnboardingChecklist {
  enabledAt: Date | null;
  categories: AccountingOnboardingChecklistCategory[];
}

export interface AccountingOnboardingChecklistCategory {
  title: string;
  tips: AccountingOnboardingChecklistTip[];
}

export interface AccountingOnboardingChecklistTip {
  type: string;
  image?: string;
  video?: string;
  title: string;
  content: string[];
  archivedAt: Date | null;
}
