import { APP_INITIALIZER, Provider } from '@angular/core';
import { CLARITY_SETTINGS_TOKEN } from '../tokens/clarity-settings.token';

export function clarityInitializer() {
  return async () => true;
}

export const CLARITY_INITIALIZER_PROVIDER: Provider = {
  provide: APP_INITIALIZER,
  multi: true,
  useFactory: clarityInitializer,
  deps: [CLARITY_SETTINGS_TOKEN],
};
