import { Injectable } from '@angular/core';
import { lastValueFrom, Observable } from 'rxjs';
import { AccountingOnboarding, AccountingOnboardingState } from '@dougs/accounting/onboarding/dto';
import { Company } from '@dougs/company/dto';
import { LoggerService } from '@dougs/core/logger';
import { StateService } from '@dougs/core/state';
import { toPromise } from '@dougs/core/utils';
import { SERVICE_STATE } from '@dougs/revenue/services/dto';
import { OnboardingHttpService } from '../http/onboarding.http';

interface OnboardingState {
  accountingOnboarding: AccountingOnboarding | null;
}

@Injectable({
  providedIn: 'root',
})
export class AccountingOnboardingStateService extends StateService<OnboardingState> {
  accountingOnboarding$: Observable<AccountingOnboarding | null> = this.select((state) => state.accountingOnboarding);

  constructor(
    private readonly logger: LoggerService,
    private readonly onboardingHttpService: OnboardingHttpService,
  ) {
    super();
  }

  async refreshAccountingOnboarding(companyId: number): Promise<AccountingOnboarding | null> {
    try {
      this.setState({
        accountingOnboarding: await lastValueFrom(this.onboardingHttpService.getAccountingOnboarding(companyId)),
      });
      return this.state.accountingOnboarding;
    } catch (e) {
      this.setState({ accountingOnboarding: null });
      this.logger.error(e);
    }

    return null;
  }

  async createAccountingOnboarding(companyId: number): Promise<void> {
    try {
      this.setState({
        accountingOnboarding: await lastValueFrom(this.onboardingHttpService.createAccountingOnboarding(companyId)),
      });
    } catch (e) {
      this.logger.error(e);
    }
  }

  async completeAccountingOnboarding(companyId: number, activationCompletedAt: Date | null): Promise<void> {
    try {
      this.setState({
        accountingOnboarding: await lastValueFrom(
          this.onboardingHttpService.completeAccountingOnboarding(companyId, activationCompletedAt),
        ),
      });
    } catch (e) {
      this.logger.error(e);
    }
  }

  async updateEligibilityParametersAccountingOnboarding(
    companyId: number,
    accountingOnboarding: AccountingOnboarding,
  ): Promise<void> {
    try {
      this.setState({
        accountingOnboarding: await lastValueFrom(
          this.onboardingHttpService.updateEligibilityParametersAccountingOnboarding(companyId, accountingOnboarding),
        ),
      });
    } catch (e) {
      this.logger.error(e);
    }
  }

  async updatePackOptionsConfiguration(
    companyId: number,
    packOptionsConfiguration: AccountingOnboarding['data']['packOptionsConfiguration'],
  ): Promise<void> {
    try {
      this.setState({
        accountingOnboarding: await lastValueFrom(
          this.onboardingHttpService.updatePackOptionsConfiguration(companyId, packOptionsConfiguration),
        ),
      });
    } catch (e) {
      this.logger.error(e);
    }
  }

  async updateDesiredPlanParameters(
    companyId: number,
    desiredPlan: AccountingOnboarding['data']['desiredPlan'] | null,
  ): Promise<void> {
    try {
      this.setState({
        accountingOnboarding: await lastValueFrom(
          this.onboardingHttpService.updateDesiredPlanParameters(companyId, desiredPlan),
        ),
      });
    } catch (e) {
      this.logger.error(e);
    }
  }

  async enableChecklist(company: Company, serviceState: SERVICE_STATE): Promise<void> {
    try {
      const accountingOnboarding: AccountingOnboarding = await toPromise(
        this.onboardingHttpService.enableChecklist(company, serviceState),
      );
      this.setState({ accountingOnboarding });
    } catch (e) {
      this.logger.error(e);
    }
  }

  async disableChecklist(company: Company): Promise<void> {
    try {
      const accountingOnboarding: AccountingOnboarding = await toPromise(
        this.onboardingHttpService.disableChecklist(company),
      );
      this.setState({ accountingOnboarding });
    } catch (e) {
      this.logger.error(e);
    }
  }

  async archiveChecklistTip(companyId: number, tipId: string): Promise<void> {
    try {
      const accountingOnboarding: AccountingOnboarding = await toPromise(
        this.onboardingHttpService.archiveChecklistTip(companyId, tipId),
      );
      this.setState({ accountingOnboarding });
    } catch (e) {
      this.logger.error(e);
    }
  }

  async unarchiveChecklistTip(companyId: number, tipId: string): Promise<void> {
    try {
      const accountingOnboarding: AccountingOnboarding = await toPromise(
        this.onboardingHttpService.unarchiveChecklistTip(companyId, tipId),
      );
      this.setState({ accountingOnboarding });
    } catch (e) {
      this.logger.error(e);
    }
  }

  get accountingOnboarding(): AccountingOnboarding | null {
    return this.state?.accountingOnboarding;
  }

  getAccountingOnboardingState(): AccountingOnboardingState {
    if (!this.accountingOnboarding) {
      return AccountingOnboardingState.UNKNOWN;
    }
    if (this.accountingOnboarding.activationCompletedAt) {
      return AccountingOnboardingState.COMPLETED;
    }
    return AccountingOnboardingState.PENDING;
  }
}
