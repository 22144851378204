import { isPlatformBrowser } from '@angular/common';
import { inject, InjectionToken, PLATFORM_ID } from '@angular/core';

// eslint-disable-next-line @typescript-eslint/ban-types
export function getWindow(platformId: Object) {
  return isPlatformBrowser(platformId) ? window : null;
}

export const HSQ_WINDOW = new InjectionToken<Window | null>('hsq-window', {
  providedIn: 'root',
  factory: () => getWindow(inject(PLATFORM_ID)),
});
