import { DOCUMENT } from '@angular/common';
import { APP_INITIALIZER, Provider } from '@angular/core';
import { ConfigHubspot } from '@dougs/core/config';
import { HSQ } from '../tokens/hubspot-hsq.token';
import { HUBSPOT_SETTINGS_TOKEN } from '../tokens/hubspot-settings.token';

export function hubspotInitializer(settings: ConfigHubspot, document: Document) {
  if (settings.disabled) {
    return () => {
      return undefined;
    };
  }
  return async () => {
    const uri = `//js.hs-scripts.com/${settings.trackingCode}.js`;
    const script: HTMLScriptElement = document.createElement('script');
    script.async = true;
    script.defer = true;
    script.src = uri;
    script.id = 'hs-script-loader';
    const head: HTMLHeadElement = document.getElementsByTagName('head')[0];
    head.appendChild(script);
  };
}

export const HUBSPOT_INITIALIZER_PROVIDER: Provider = {
  provide: APP_INITIALIZER,
  multi: true,
  useFactory: hubspotInitializer,
  deps: [HUBSPOT_SETTINGS_TOKEN, DOCUMENT, HSQ],
};
