import { APP_INITIALIZER, Provider } from '@angular/core';
import { MIXPANEL_SETTINGS_TOKEN } from '../tokens/mixpanel-settings.token';

export function mixpanelInitializer() {
  return async () => true;
}

export const MIXPANEL_INITIALIZER_PROVIDER: Provider = {
  provide: APP_INITIALIZER,
  multi: true,
  useFactory: mixpanelInitializer,
  deps: [MIXPANEL_SETTINGS_TOKEN],
};
