import { inject, InjectionToken } from '@angular/core';
import { Hsq } from '../types/hsq.type';
import { HSQ_WINDOW } from './hubspot-window.token';

export function getHsqFn(window: Window | null): Hsq | null {
  return window ? ((window as any)._hsq = (window as any)._hsq || []) : null;
}
export const HSQ = new InjectionToken<Hsq | null>('hsq', {
  providedIn: 'root',
  factory: () => getHsqFn(inject(HSQ_WINDOW)),
});
