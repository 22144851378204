import { AccountingOnboarding } from '../dto';

const getDefaults = (): AccountingOnboarding => ({
  data: {
    accountingInitialAppointment: {
      accountantName: '',
      accountantAvatarUrl: '',
      completedAt: new Date('2022-11-21'),
      completionReasonCode: '',
      completionReasonComment: '',
      scheduledAt: new Date('2022-11-21'),
    },
    webinar: {
      attendanceRate: 1,
      attendedAt: new Date('2022-11-21'),
      registeredAt: new Date('2022-11-21'),
    },
    workshops: [],
    shouldShowCompanyCreationService: false,
  },
  companyId: 1,
});

export const getAccountingOnboardingMock = (p?: Partial<AccountingOnboarding>): AccountingOnboarding => ({
  ...getDefaults(),
  ...p,
});
