import { DOCUMENT } from '@angular/common';
import { Inject, Injectable } from '@angular/core';
import { ClaritySettings } from '../interfaces/clarity-settings.interface';
import { CLARITY_SETTINGS_TOKEN } from '../tokens/clarity-settings.token';

@Injectable({
  providedIn: 'root',
})
export class ClarityService {
  constructor(
    @Inject(CLARITY_SETTINGS_TOKEN) private readonly settings: ClaritySettings,
    @Inject(DOCUMENT) private readonly document: Document,
  ) {}

  private readonly ID = 'clarityjs';

  public loadClarity() {
    if (this.document.getElementById(this.ID)) {
      return;
    }
    const script: HTMLScriptElement = this.document.createElement('script');
    script.id = this.ID;
    script.type = 'text/javascript';
    script.text = `(function(c,l,a,r,i,t,y){
          c[a]=c[a]||function(){(c[a].q=c[a].q||[]).push(arguments)};
          t=l.createElement(r);t.async=1;t.src="https://www.clarity.ms/tag/"+i;
          y=l.getElementsByTagName(r)[0];y.parentNode.insertBefore(t,y);
        })(window, document, "clarity", "script", "${this.settings.trackingCode}");`;
    this.document.head.appendChild(script);
  }

  public unloadClarity() {
    const clarityElement = this.document.getElementById(this.ID);
    if (clarityElement) {
      clarityElement.remove();
    }
  }
}
