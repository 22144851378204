import { Injectable } from '@angular/core';
import { Hsq } from '../types/hsq.type';

@Injectable({
  providedIn: 'root',
})
export class HubspotService {
  private static checkIfExistsAndIsArray() {
    return HubspotService.hsq && Array.isArray(HubspotService.hsq);
  }

  private static get hsq(): Hsq {
    return (window as any)._hsq;
  }

  identify(id: number | undefined, email: string, extraFields?: Record<string, unknown>): void {
    HubspotService.checkIfExistsAndIsArray() &&
      HubspotService.hsq.push(['identify', id ? { id, email, ...extraFields } : { email, ...extraFields }]);
  }

  trackPageView(pageName: string): void {
    HubspotService.checkIfExistsAndIsArray() && HubspotService.hsq.push(['setPath', pageName]);
    HubspotService.checkIfExistsAndIsArray() && HubspotService.hsq.push(['trackPageView']);
  }
}
